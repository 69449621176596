<template>
  <div>
    <window-header></window-header>
    <nav-form></nav-form>
    <section>
      <article style="height:402px; width:707px">
        <img
          :src="formData.thumbnail && typeof formData.thumbnail == 'object' ? formData.thumbnail.imagen : require('./../assets/noimage.png')"
          style="position:absolute;width:52px; height:52px; top: 4px; left:6px;" />
        <field name="codigo" widget="char" searchable label="Código" help="Código del modelo" placeholder="Cód. Modelo"
          width="100px" style="top:9px; left:68px;" :readonly="mode == 'edit'"
          inputStyle="font-weight:bold;text-align:center;" @input="sequence.cancel = formData.codigo ? true : false" />
        <field name="nombre" widget="char" searchable label="Descripción" help="Descripción del modelo" required
          placeholder="Descripción del modelo" width="300px" style="top:9px; left:180px;" />
        <field name="activo" value="true" widget="toggle" searchable
          :labels="{ checked: 'Activo', unchecked: 'Inactivo' }" :width="70" inline-label style="top:20px; left:490px;" />
        <r-tabs ref="main-tabs" style="top:55px;left:0px;height:345px;" :buttons="[
          'General',
          'O.Datos',
          'Imagen',
          'Inventario',
          'Certificados',
          'Notas'
        ]">
          <div class="tab">
            <fieldset style="position: absolute; left: 10px; top: 5px; width: 330px; height: 55px">
              <legend>Unidad de Medida en Compras:</legend>
              <field name="unidad_medida_compra" widget="radio" default="unidad"
                :options="{ 'Unidad': 'unidad', 'Peso': 'peso' }" inline-label style="top:6px; left:15px;" />
              <field name="compra_metal_incluido" widget="checkbox" label="Compra con Metal Incluido" inline-label
                style="top:8px; left:147px;" />
            </fieldset>
            <fieldset style="position: absolute; right: 10px; top: 5px; width: 330px; height: 55px">
              <legend>Unidad de Medida en Ventas:</legend>
              <field name="unidad_medida_venta" widget="radio" default="unidad"
                :options="{ 'Unidad': 'unidad', 'Peso': 'peso' }" inline-label style="top:6px; left:15px;" />
              <field name="venta_metal_incluido" widget="checkbox" label="Venta con Metal Incluido" inline-label
                style="top:8px; left:147px;" />
            </fieldset>
            <field name="familia_id" searchable widget="m2o" dbAdapter="familia" label="Familia" inline-label
              placeholder="Familia" width="270px" style="top:65px; right:353px;" required :fields="[
                'unidad_medida_compra',
                'compra_metal_incluido',
                'unidad_medida_venta',
                'venta_metal_incluido',
                'recargo_equivalencia',
                'nombre',
                'control_inventario',
                { name: 'tipo_comision_id', fields: ['descripcion'] },
                { name: 'seccion_id', fields: ['nombre'] },
                { name: 'atributos', fields: ['nombre'] }]" @select="onSelectFamilia" ref="familia_id" />
            <field name="seccion_id" searchable widget="m2o" dbAdapter="seccion" label="Sección" inline-label required
              help="Sección a la que pertenece la Familia" width="270px" style="top:90px; right:353px;" />

            <field name="recargo_equivalencia" inline-label widget="toggle" :labels="{ checked: 'Sí', unchecked: 'No' }"
              label="R. Equiv." :width="50" style="top:115px; left:220px;" />

            <fieldset style="position: absolute; left: 10px; bottom: 9px; width: 330px; height: 155px">
              <legend>Artículos:</legend>
              <field name="articulos" widget="handsontable" readonly searchable :height="130" :minRows="8"
                style="top:3px; left:10px; width: 310px;" :columns="[
                  {
                    name: 'ean13',
                    header: '|||||||||',
                  },
                  {
                    name: 'codigo',
                    header: 'Código',
                  },
                  {
                    name: 'nombre',
                    header: 'Descripción',
                    readOnly: true,
                  },
                  {
                    name: 'familia_id',
                    header: 'Familia',
                    type: 'm2o',
                    primary: 'codigo',
                    label: 'nombre',
                    showCode: true,
                    readOnly: true,
                  },
                  {
                    name: 'seccion_id',
                    header: 'Sección',
                    type: 'm2o',
                    primary: 'codigo',
                    label: 'nombre',
                    showCode: true,
                    readOnly: true,
                  },
                ]" />
            </fieldset>

            <fieldset style="position: absolute; right: 10px; bottom: 9px; width: 330px; height: 227px">
              <legend>Atributos:</legend>
              <field name="atributos" widget="handsontable" searchable :height="202" :minRows="8"
                style="top:3px; left:10px; width: 310px;" :htSettings="htSettingsAtributos" :columns="[
                  { name: 'atributo_id', header: 'Atributo', type: 'm2o', label: 'nombre' },
                  {
                    name: 'id', header: 'Valor', type: 'm2o', label: 'nombre', model: 'valor_atributo',
                    filter: (value, row, prop) =>
                      value && value['atributo_id']
                        ? ['atributo_id', '=', value['atributo_id']]
                        : [],
                  }
                ]" />
            </fieldset>
          </div>
          <div class="tab">
            <field name="marca_id" widget="m2o" dbAdapter="marca" searchable label="Marca" inline-label placeholder="0"
              width="200px" style="top:50px; right:420px;" />

            <field name="proveedor_id" searchable widget="m2o" dbAdapter="proveedor" label="Proveedor"
              labelProp="nombre_comercial" inline-label width="300px" style="top:20px; right:15px;" />
            <field name="codigo_proveedor" widget="int" searchable label="Ref. Proveedor" inline-label placeholder="0"
              width="300px" style="top:50px; right:14px;" />
            <field name="tipo_comision_id" widget="m2o" searchable dbAdapter="comision" labelProp="descripcion"
              label="Tipo Comision" inline-label width="300px" style="top:80px; right:15px;" />

            <field name="colecciones" label="Colecciones" widget="m2m" dbAdapter="coleccion" searchable width="320px"
              style="top:140px; left:15px;" :height="130" />
            <field name="taqueria_ids" searchable widget="m2m" dbAdapter="articulo" label="Taquería"
              :filter="['seccion_id', '=', 'PCK']" position-label="block" help="Taquería" width="320px"
              style="top:140px; right:15px;" :height="130" imgProp="thumbnail" />
          </div>
          <div class="tab">
            <field name="thumbnail" widget="m2o_img" style="width:250px; height:250px; top: 10px; left:20px;"
              dbAdapter="imagen_articulo" searchable />
            <field name="thumbnail1" widget="m2o_img" style="width:100px; height:100px; top: 10px; left:320px;"
              dbAdapter="imagen_articulo" searchable />
            <field name="thumbnail2" widget="m2o_img" style="width:100px; height:100px; top: 10px; left:520px;"
              dbAdapter="imagen_articulo" searchable />
            <field name="thumbnail3" widget="m2o_img" style="width:100px; height:100px; top: 160px; left:320px;"
              dbAdapter="imagen_articulo" searchable />
            <field name="thumbnail4" widget="m2o_img" style="width:100px; height:100px; top: 160px; left:520px;"
              dbAdapter="imagen_articulo" searchable />
          </div>
          <div class="tab">
            <field name="inventario" widget="handsontable" :height="275" :width="480" :minRows="11"
              style="top:10px; left:4px; height:100px; width: 420px;" :readonly="true" :columns="[
                { name: 'almacen_id', header: 'Almacen', widget: 'm2o' },
                { name: 'unidad', header: 'Unidad', type: 'numeric', format: '0,0.000' },
                { name: 'peso', header: 'Peso', type: 'numeric', format: '0,0.000' }
              ]" :fields="['wt']" />
            <fieldset style="position: absolute; right: 10px; top: 10px; width: 190px; height: 60px">
              <legend>Control Inventario</legend>
              <field name="control_inventario" widget="radio" default="input"
                :options="{ 'Unidad': 'unidad', 'Peso': 'peso', 'Ambos': 'ambos' }" style="top:6px; left:15px;" />
            </fieldset>
            <!--<fieldset style="position: relative; left: -8px; top: 192px; width: 270px; height: 59px">
                    <legend>Control de inventario:</legend>
                    <div class="field" name="controlinventario" widget="radio" default="unidades" options='{"Unidades":"unidades", "Peso":"peso", "Ambos":"ambos"}' inline-label style="top:23px; left:15px;"/>
                </fieldset>
            <div class="field" name="mostrarinventario" widget="toggle" ontext="Si" offtext="No" label="Mostrar inventario" width="45" inline-label style="top:260px; right:240px;"/>-->
          </div>
          <div class="tab">
            <field name="certificados" widget="handsontable" :height="258" :width="680" :minRows="11"
              style="top:10px; left:10px; width: 690px;" customprop="certificados_customprop" :columns="[
                { name: 'naturaleza', header: 'Naturaleza', type: 'm2o', label: 'descripcion', primary: 'codigo', showCode: true },
                { name: 'talla', header: 'Talla', type: 'm2o', label: 'descripcion', primary: 'codigo' },
                { name: 'npiedras', header: 'N. Piedras', type: 'numeric', numericFormat: { pattern: '0,0.' } },
                { name: 'pesogema', header: 'Peso Gema', type: 'numeric', numericFormat: { pattern: '0,0.000' } },
                { name: 'color', header: 'Color', type: 'm2o', label: 'descripcion', primary: 'codigo' },
                { name: 'pureza', header: 'Pureza', type: 'm2o', label: 'descripcion', primary: 'codigo' }
              ]" />
            <field name="material" searchable label="Material" inline-label help="Material" width="120px"
              style="bottom: 10px; left: 10px" />
            <field name="peso" widget="float" type="number" searchable label="Peso" inline-label help="Peso" width="80px"
              style="bottom: 10px; left: 190px" suffix=" gr" />
          </div>
          <div class="tab">
            <field name="notas" widget="text" placeholder="Escriba aquí las notas del modelo..." width="670px"
              height="270px" style="top:10px; left:12px;" />
          </div>
        </r-tabs>
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        Listado
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function () {
    var self = this;
    return {
      title: "Modelos",
      dbAdapter: "modelo",
      primary: "codigo",
      defaultData: {
        activo: 1
      },
      sequence: {
        cancel: false,
        name: "modelo",
        params: [
          "familia_id.codigo",
          "seccion_id.codigo",
          "proveedor_id.codigo"
        ]
      },
      htSettingsAtributos: {
        cells(row, col, prop) {
          let readOnly = prop == 'id' && !this.instance.getSourceData()[row].atributo_id;
          return readOnly ? { readOnly } : {}
        },
        beforeChange(changes, source) {
          if (!changes) return;
          let h = this;
          changes.forEach(function (change, index) {
            if (!change) return;
            const row = change[0];
            const prop = change[1];
            const newVal = change[3];
            if (prop == 'id' && h.getSourceData().find(x => x.id == newVal)) {
              self.app.toast('Ya está añadido ese valor de atributo', 'error');
              changes[index][3] = null;
            }
          });
        }
      }
    };
  },
  methods: {
    onSelectFamilia() {
      var self = this;
      if (self.mode != "new" && self.mode != "edit") return;
      if (!self.formData.familia_id) return;
      for (let c of self.$refs.familia_id.field.fields) {
        if (typeof c == "object") c = c.name;
        var v = self.formData.familia_id[c];
        if (c == "atributos") {
          v = (self.formData.familia_id.atributos || []).map(el => ({
            atributo_id: el
          }));
        }
        self.$set(self.formData, c, v);
      }
    }
  }
};
</script>
