<script>
import { HotTable } from "@handsontable/vue";
import Handsontable from "handsontable";

export default {
  components: {
    HotTable,
  },
  inject: ["app"],
  computed: {
    colHeadersHt() {
      var self = this;
      return function (col) {
        if (typeof self.columnsData[col] == "undefined") return "-";
        let h = self.columnsData[col];
        return (
          "<span title='" +
          (h.help || "") +
          "'>" +
          (h.header || h.name) +
          "</span>" +
          (self.order == h.name
            ? "<span>" + (self.orderDir == "ASC" ? "🠅" : "🠇") + "</span>"
            : "")
        );
      };
    },
    colWidthsHt() {
      return this.columnsData.map((c) => c.width || 100);
    },
    columnsHt() {
      var self = this;
      var cols = [];
      for (let c of self.columnsData) {
        var col = { data: c.name };
        if (c.type == "boolean") {
          col.type = "checkbox";
          col.checkedTemplate = "1";
          col.uncheckedTemplate = "0";
          col.allowInvalid = false;
          col.allowEmpty = false;
        } else if (c.type == "date") {
          col.type = "date2";
          /*window.$.extend(true, col, {
            type: "date",
            dateFormat: "YYYY-MM-DD",
            correctFormat: true,
            /*renderer: function() {
                  //if (value) value = APP.UTILS.formatDate(value);
                  //Handsontable.renderers.DateCell.renderer.apply(this, arguments);
                },
            sortFunction: function(sortOrder) {
              return function(a, b) {
                if (sortOrder) return a[1] >= b[1];
                else return a[1] <= b[1];
              };
            }
          });*/
        } else if (c.type == "datetime") {
          col.renderer = function (
            hotInstance,
            td,
            row,
            column,
            prop,
            value,
            cellProperties
          ) {
            if (value) {
              let d = new Date(value);
              value = d.ddmmyyyyhhiiss();
            }
            Handsontable.renderers.TextRenderer.apply(this, [
              hotInstance,
              td,
              row,
              column,
              prop,
              value,
              cellProperties,
            ]);
          };
        }
        cols.push(col);
      }
      return cols;
    },
    htSettings() {
      var self = this;
      return {
        ...{
          licenseKey: "non-commercial-and-evaluation",
          rowHeaders: true,
          width: "100%", //660,
          height: 162,
          columnSorting: true,
          sortIndicator: true,
          readOnly: true,
          manualColumnResize: true,
          manualColumnMove: true,
          columns: self.columnsHt,
          colHeaders: self.colHeadersHt,
          colWidths: self.colWidthsHt,
          data: self.items,
          currentRowClassName: "currentRow",
          minRows: 6,
          rowHeights: 20,
          afterSelectionEnd: function (r, c, r2, c2) {
            var itemId = self.htSettings.data[r2][self.primary];
            if (!itemId) return;
            if (r != r2) return;
            self.loadItem(itemId);
            /*self.$emit("selected", {
              id: self.htSettings.data[r2][self.primary],
              data: self.htSettings.data[r2],
              row: r2
            });*/
          },
          beforeColumnMove(cls, target) {
            let colStart = cls[0];
            let nCols = cls.length;
            self.columnsData.splice(
              target > colStart ? target - nCols : target,
              0,
              ...self.columnsData.splice(colStart, nCols)
            );
            return false;
          },
          afterColumnResize(c, w) {
            this.getPlugin("ManualColumnResize").clearManualSize(c);
            //this.render();
            self.$set(self.columnsData[c], "width", w);
          },
          contextMenu: {
            items: {
              hide_column: {
                name: "Ocultar columna",
                callback: function (key, opt) {
                  var h = this;
                  let sel = h.getSelected()[0];
                  let startCol = sel[1];
                  self.columnsData.splice(startCol, 1);
                },
              },
              hsep2: "---------",
              save: {
                name: "Guardar estructura",
                callback: function () {
                  self.saveTableStructure();
                },
              },
              restore: {
                name: "Restaurar estructura",
                callback: function () {
                  self.restoreTableStructure();
                },
              },
            },
          },
          beforeColumnSort: function (
            currentSortConfig,
            destinationSortConfigs
          ) {
            this.deselectCell();
            self.order = this.colToProp(destinationSortConfigs[0].column);
            self.orderDir = self.orderDir == "ASC" ? "DESC" : "ASC";
            self.initRemoteData("filter");
            return false;
          },
        },
        ...self.additionalHtSettings,
      };
    },
  },
  data: function () {
    var self = this;
    return {
      self: this,
      loadingTable: false,
      nomore: false,
      page: 1,
      order: "",
      orderDir: "ASC",
      conditions: this.filter,
      count: null,

      rowsPerPage: 90,
      primary: "id",
      filter: [],
      columnsData: [],
      items: [],
      additionalHtSettings: {},
      loadItemIfOne: true,
    };
  },
  props: {
    /*structureRef: {},
    model: { type: String, required: true },
    dbAdapter: {
      type: Object,
      required: true
    },
    rowsPerPage: {
      type: Number,
      default: 60
    },
    primary: {
      type: String,
      default: "id"
    },
    sort: {
      type: String,
      default: ""
    },
    sortDir: {
      type: String,
      default: "ASC"
    },
    filter: {
      default: function() {
        return [];
      }
    }*/
  },
  mounted: function () {
    var self = this;
    var th = 300;
    if (self.defaultDataSearch) {
      self.formData = self.$utils.misc.clone(self.defaultDataSearch);
      self.conditions = self.getFilter();
    }
    if (this.$refs.hotTableComponent) {
      var $el = window.$(this.$refs.hotTableComponent.$el);
      this.$refs.hotTableComponent.hotInstance.addHook(
        "afterScrollVertically",
        function (e) {
          if (self.loadingTable || self.nomore) return;
          var offset = $el.find(".ht_master >.wtHolder").scrollTop();
          var heightHolder = $el.find(".ht_master >.wtHolder").height();
          var heightHider = $el.find(".ht_master >.wtHolder>.wtHider").height();
          if (
            heightHider > heightHolder &&
            heightHider - heightHolder - offset < th
          ) {
            self.page++;
            self.getRemoteData();
          }
        }
      );
      self.refreshTableStructure().then(self.initRemoteData());
    } else if (this.$refs.rlist) {
      $el = window.$(this.$refs.rlist.$el || this.$refs.rlist);
      let $ = window.$;
      $el.scroll(function () {
        if (self.loadingTable || self.nomore) return;
        if (
          $(this).scrollTop() + $(this).innerHeight() + th >=
          $(this)[0].scrollHeight
        ) {
          self.page++;
          self.getRemoteData();
        }
      });
      self.initRemoteData();
    } else {
      self.initRemoteData();
    }
  },
  methods: {
    consolelog(a, b, c) {
      window.console.log(a, b, c);
    },
    initRemoteData: function (source = "normal") {
      var self = this;
      if (self.loadingTable) return;
      self.page = 1;
      self.nomore = false;
      self.clearData();
      self.loadingTable = true;
      self.getRemoteData(true, source);
    },
    getRemoteData: function (clear, source = "normal") {
      var self = this;
      self.loadingTable = true;
      var params = {
        filter: self.conditions,
        limit: self.rowsPerPage == -1 ? null : self.rowsPerPage,
        offset:
          self.rowsPerPage == -1 ? null : self.rowsPerPage * (self.page - 1),
        page: self.page,
        order: self.order || self.primary,
        orderDir: self.orderDir,
        format: "tree",
        depth: 2,
      };
      window.DB.getList(self.dbAdapter, params)
        .then(function (res) {
          if (!res.data.length && parseInt(res.count)) {
            self.page = self.page - 1;
            self.nomore = true;
          } else {
            if (clear) self.clearData();
            self.addData(res.data);
          }
          if (!parseInt(res.count) && source == "filter") {
            self.app.toast("No se ha encontrado ningún registro", "error");
          }
          self.count = res.count;
          if (
            res.count == 1 &&
            self.formData &&
            !self.initialFormData &&
            self.loadItemIfOne &&
            self.mode != "new" &&
            self.mode != "edit" &&
            (self.formData['ndoc'] || self.formData[self.primary])
          )
            self.loadItem(res.data[0][self.primary]);
          self.$emit("load");
        })
        .catch(function (e) {
          if (typeof e == "string") e = { error: e };
          let error = e.msg || e.error;
          self.app.toast({
            text: error,
            position: "center",
            closeTimeout: 2000,
          });
        })
        .finally(function () {
          self.loadingTable = false;
        });
    },
    load: function (data) {
      var self = this;
      self.clearData();
      self.addData(data);
    },
    addData: function (data) {
      var self = this;
      //self.htSettings.data = [...self.htSettings.data, ...data];
      self.items = [...self.items, ...data];
      //window.pep = self.$refs.hotTableComponent;
    },
    clearData: function () {
      var self = this;
      //self.htSettings.data = [];
      self.items = [];
    },
    removeData: function (id) {
      var self = this;
      for (var i = self.items.length - 1; i >= 0; --i) {
        if (self.items[i][self.primary] == id) {
          self.items.splice(i, 1);
        }
      }
    },
    setFilter: function (filter) {
      var self = this;
      self.conditions = filter;
      //window.console.log(filter);
      self.initRemoteData("filter");
    },
    refreshTableStructure: function () {
      var self = this;
      return window.DB.action("table_structure", "getStructure", {
        data: {
          id: self.structureRef ? self.structureRef : self.dbAdapter,
          model: self.dbAdapter,
        },
      })
        .then(function (res) {
          res = res.data;
          var cols = [];
          for (let i in res.columns) {
            let c = {
              name: res.columns[i],
              type: res.types[i],
              header: res.colHeaders[i],
              width: res.colWidths[i],
              help: res.colDescriptions[i],
            };
            cols.push(c);
          }
          self.columnsData = cols;
          /*var cols = [];
          window.$.each(res.columns, function(i, el) {
            var col = { data: el };
            if (res.types[i] == "boolean") {
              col.type = "checkbox";
              col.checkedTemplate = "1";
              col.uncheckedTemplate = "0";
              col.allowInvalid = false;
              col.allowEmpty = false;
            } else if (res.types[i] == "date" || res.types[i] == "datetime") {
              window.$.extend(true, col, {
                type: "date",
                dateFormat: "YYYY-MM-DD",
                correctFormat: true,
                //renderer: function() {
                  //if (value) value = APP.UTILS.formatDate(value);
                  //Handsontable.renderers.DateCell.renderer.apply(this, arguments);
                //},
                sortFunction: function(sortOrder) {
                  return function(a, b) {
                    if (sortOrder) return a[1] >= b[1];
                    else return a[1] <= b[1];
                  };
                }
              });
            }
            cols.push(col);
          });
          self.htSettings.columns = cols;
          self.htSettings.colHeaders = res.colHeaders.length
            ? res.colHeaders
            : res.columns;
          self.htSettings.colWidths = res.colWidths;*/
        })
        .catch(function (e) {
          window.console.log(e);
        });
    },
    restoreTableStructure() {
      var self = this;
      //self.$set(self, "columnsData", self.$utils.misc.clone(self.columns));
      window.DB.action("table_structure", "restoreStructureField", {
        data: {
          id: self.structureRef ? self.structureRef : self.dbAdapter,
        },
        method: "POST",
      })
        .then(function () {
          self.app.toast("Estructura restaurada con éxito");
          self.refreshTableStructure();
        })
        .catch(function (e) {
          self.app.toast(e);
        });
    },
    saveTableStructure() {
      var self = this;
      var h = self.$refs.hotTableComponent.hotInstance;
      var widths = Array();
      var cols = Array();
      for (let cd of self.columnsData) {
        cols.push(cd.name);
        widths.push(cd.width || null);
      }
      window.DB.action("table_structure", "saveStructureField", {
        data: {
          id: self.structureRef ? self.structureRef : self.dbAdapter,
          columns: cols,
          colWidths: widths,
        },
        method: "POST",
      })
        .then(function () {
          self.app.toast("Estructura almacenada con éxito");
        })
        .catch(function (e) {
          self.app.toast(e);
        });
    },
  },
  directives: {
    "list-item": {
      inserted: function (el) {
        alert(12);
      },
    },
  },
};
</script>
<style>
/*.currentRow,
.highlight {
  background: rgba(0, 140, 186, 0.1);
  color: black !important;
}
.handsontable th,
.handsontable td {
  white-space: nowrap;
}*/

.rlist-list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: baseline;
}

.rlist-item {
  margin: 3px;
  padding: 3px;
  cursor: pointer;
  width: 102px;
  border: 2px solid transparent;
}

.rlist-item>img {
  box-shadow: 3px 3px 3px #bbb;
}

.rlist-item-selected {
  border: 2px solid var(--color-primary-dark);
  background: var(--color-primary-light);
}

.rlist-item {
  display: inline-block;
}

.rlist-item img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
</style>